import React from 'react'
import { createGlobalStyle } from 'styled-components'
import s from '../../styles'

const ui = {}
ui.Root = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${s['font-family']};
    font-size: ${s['font-size']};
    background: ${s['color-light-silver']};
    color: ${s['color-dirty-blue']};
    min-height: 100vh;
    padding: 16px;
  }
`

export default function UiRoot({ children }) {
  return (
    <React.Fragment>
      <ui.Root />
      {children}
    </React.Fragment>
  )
}
