/* @flow */
import React from 'react'
import ReactDOM from 'react-dom'
import 'sanitize.css'
import styled from 'styled-components'
import s from './styles'
import GridLayout from 'react-grid-layout'
import UiRoot from './components/UiRoot'
import UiButton from './components/UiButton'
import 'react-grid-layout/css/styles.css'
import immer from 'immer'


type ILayout = {
  i: 'string',
  x: number,
  y: number,
  w: number,
  h: number,
  data: {
    widget_name: 'market_appraisal' | 'property_alerts' | 'opens'
  }
}

type IAppState = {
  layout: Array<ILayout>
}

const settings = {
  COLUMN_COUNT: 4,
  ROW_HEIGHT: 120,
  GRID_WIDTH: 720,
  GRID_PADDING: 8,
  GRID_GUTTER: 8,
  DRAGGABLE_HANDLE_SELECTOR: 'draggable-handle',
  DEFAULT_WIDGET: 'market_appraisal'
}

const WIDGET_OPTIONS = [
  {
    label: 'Market Appraisal',
    value: 'market_appraisal'
  },
  {
    label: 'Property Alerts',
    value: 'property_alerts'
  },
  {
    label: 'Opens',
    value: 'opens'
  }
]

const ui = {}
ui.Menu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
`
ui.MenuTitle = styled.h4`
  margin: 0;
  font-size: 24px;
  font-weight: 800;
  color: ${s['color-blue']};
  letter-spacing: -0.5px;
`
ui.Container = styled.div`
  display: flex;
`
ui.ContainerRight = styled.div`
  width: 100%;
`
ui.ContainerLeft = styled.div`
  flex-shrink: 0;
  margin-right: 48px;
  // We'll adjust here so the grid doesn't have to.
  width: ${settings.GRID_WIDTH + (settings.GRID_PADDING / 2)}px;
`
ui.GridContainer = styled.div`
  border: 1px solid ${s['color-silver']};
`
ui.Box = styled.div`
  padding: 12px;
  background: ${s['color-silver']};
  border-radius: 4px;
  border: 1px solid ${s['color-light-silver']};
  transition: border 200ms all ease;

  :hover {
    border-color: ${s['color-dark-silver']};
  }

  &.react-draggable-dragging {
    cursor: grabbing;
  }
`
ui.BoxRemove = styled.button`
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  opacity: 0;

  ${ui.Box}:hover & {
    opacity: 1;
    transition: 200ms all ease;
  }
`
ui.BoxSelect = styled.div`
  position: relative;
  max-width: 160px;
`
ui.BoxSelectInput = styled.select`
  display: block;
  width: 100%;
  padding: 8px;
  padding-right: 16px;
  background: #eef6ff;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  outline: 0;
  font-size: 12px;

  // Firefox
  -moz-appearance: none;
  // Chrome
  -webkit-appearance: none;
`
ui.BoxSelectArrow = styled.span`
  position: absolute;
  top: 6px;
  right: 8px;
  pointer-events: none;
`
ui.BoxLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 4px;
`
ui.BoxDragHandler = styled.span`
  cursor: grabbing;
  font-size: 14px;
  width: 16px;
`
ui.LayoutPreview = styled.pre`
  display: block;
  width: 100%;
  margin: 0;
  height: 400px;
  overflow: auto;
  border: 1px solid ${s['color-silver']};
  padding: 8px;
`

class App extends React.Component<void, void, IAppState> {
  state = {
    layout: [
      { i: 'a', x: 0, y: 0, w: 1, h: 1 },
      { i: 'b', x: 1, y: 0, w: 2, h: 1 },
      { i: 'c', x: 0, y: 1, w: 2, h: 1 }
    ],
    data: {
      a: { widget_name: settings.DEFAULT_WIDGET },
      b: { widget_name: settings.DEFAULT_WIDGET },
      c: { widget_name: settings.DEFAULT_WIDGET }
    },
    id: 0
  }

  handleLayoutChange = layout => {
    this.setState({ layout })
  }

  handleAddBox = () => {
    const id = `n-${this.state.id}`

    this.setState({
      layout: [
        ...this.state.layout,
        {
          i: id,
          x: (this.state.layout.length * 2) % settings.COLUMN_COUNT,
          y: Infinity,
          w: 1,
          h: 1,
          data: {
            widget_name: settings.DEFAULT_WIDGET
          }
        }
      ],
      data: immer(this.state.data, (data) => {
        data[id] = {
          widget_name: settings.DEFAULT_WIDGET
        }
      }),
      id: this.state.id + 1
    })
  }

  handleRemove = (id: string) => {
    this.setState({
      layout: this.state.layout.filter((card) => id !== card.i),
      data: immer(this.state.data, (data) => {
        delete data[id]
      })
    })
  }

  handleBoxWidgetChange = id => {
    return evt => {
      this.setState({
        data: immer(this.state.data, data => {
          data[id].widget_name = evt.target.value
        })
      })
    }
  }

  render() {
    return (
      <UiRoot>
        <ui.Container>
          <ui.ContainerLeft>
            <ui.Menu>
              <ui.MenuTitle>Masonry Blocks</ui.MenuTitle>
              <UiButton onClick={this.handleAddBox}>Add New Items</UiButton>
            </ui.Menu>
            <ui.GridContainer>
              <GridLayout
                layout={this.state.layout}
                cols={settings.COLUMN_COUNT}
                rowHeight={settings.ROW_HEIGHT}
                onLayoutChange={this.handleLayoutChange}
                width={settings.GRID_WIDTH}
                margin={[settings.GRID_GUTTER, settings.GRID_GUTTER]}
                draggableHandle={`.${settings.DRAGGABLE_HANDLE_SELECTOR}`}>
                {this.state.layout.map((card, i: number) => {
                  const data = this.state.data[card.i]

                  return (
                    <ui.Box key={card.i}>
                      <ui.BoxLabel>
                        <ui.BoxDragHandler className={settings.DRAGGABLE_HANDLE_SELECTOR}>
                          <i className="fa fa-ellipsis-v" />
                        </ui.BoxDragHandler>
                        Widget
                      </ui.BoxLabel>
                      <ui.BoxSelect>
                        <ui.BoxSelectInput value={data.widget_name} onChange={this.handleBoxWidgetChange(card.i)}>
                          {WIDGET_OPTIONS.map((option, i: number) => (
                            <option value={option.value} key={i}>
                              {option.label}
                            </option>
                          ))}
                        </ui.BoxSelectInput>
                        <ui.BoxSelectArrow>
                          <i className="fa fa-caret-down" />
                        </ui.BoxSelectArrow>
                      </ui.BoxSelect>
                      <ui.BoxRemove className="remove" onClick={() => this.handleRemove(card.i)}>
                        🞨
                      </ui.BoxRemove>
                    </ui.Box>
                  )
                })}
              </GridLayout>
            </ui.GridContainer>
          </ui.ContainerLeft>

          <ui.ContainerRight>
            <ui.Menu>
              <ui.MenuTitle>Data Preview</ui.MenuTitle>
            </ui.Menu>

            <ui.LayoutPreview>{JSON.stringify(this.state.layout.map(card => {
              return { ...card, data: this.state.data[card.i] }
            }), null, 2)}</ui.LayoutPreview>
          </ui.ContainerRight>
        </ui.Container>
      </UiRoot>
    )
  }
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
